(window as any).env = process.env.environment === 'production'
  ? {
    apiVersion: '${API_VERSION}',
    environment: '${ENVIRONMENT}',

    lobbyPublicUrl: '${LOBBY_PUBLIC_URL}',

    enablePendo: '${ENABLE_PENDO}',

    newRelicAccountId: '${NEW_RELIC_ACCOUNT_ID}',
    newRelicAgentId: '${NEW_RELIC_AGENT_ID}',
    newRelicApplicationId: '${NEW_RELIC_APPLICATION_ID}',
    newRelicLicenseKey: '${NEW_RELIC_LICENSE_KEY}',
    newRelicTrustKey: '${NEW_RELIC_TRUST_KEY}',

    staticPublicUrl: '${STATIC_PUBLIC_URL}',

    commentGracePeriodHours: '${COMMENT_GRACE_PERIOD_HOURS}',

    helpCenterUrl: '${HELP_CENTER_URL}'
  }
  : {
    staticPublicUrl: 'https://static.local.proliance360.com',
    lobbyPublicUrl: 'https://local.proliance360.com',
    environment: process.env.proxyMode || 'local',
    commentGracePeriodHours: 1,
    helpCenterUrl: 'https://helpcenter.proliance360.com/support/solutions'

    // newRelicAccountId: '4174862',
    // newRelicAgentId: '538544409',
    // newRelicApplicationId: '538544409',
    // newRelicLicenseKey: 'NRJS-a1404e8e7b45f74b404',
    // newRelicTrustKey: '4174862'
  }; // Can be used for testing purposes
